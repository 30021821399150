import React, { useState, useRef } from "react";
import toast from "react-hot-toast";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useQuery } from "react-query";
import logo from "../../images/assets/TestAssessify_logo.png";
import { api } from "../../api/index";

import SuccessfulReg from "./successfulReg";
import Input from "./../../components/Global/Input";
import { useCustomToast } from "../../components/customtoast/CustomToastProvider";
import ReactGA from "react-ga";

function Register({ closeModal }) {
  const ref = useRef();
  const customToast = useCustomToast();
  const methods = useForm();
  const {
    watch,
    formState: { errors },
  } = methods;
  const location = useLocation();
  const navigate = useNavigate();
  const previousRoute = location.state?.from;
  const [openSuccessfulModal, setOpenSuccessfulModal] = useState(false);

  // Extract the slug and referral from url
  const slug = location.pathname.split("/")[2];
  const referral = new URLSearchParams(location.search).get("referral");

  sessionStorage.setItem("slug", slug || null);
  sessionStorage.setItem("referral", referral || null);

  let payload = {
    slug: location.pathname.split("/")[2],
    referral: new URLSearchParams(location.search).get("referral"),
  };

  const { isLoading, data, isError, error, refetch } = useQuery(
    ["verify-campaign-data"],
    async () => {
      const data = await api.verifyCampaignData(payload);
      return data;
    }
  );

  if (slug && referral !== "null") {
    refetch();
  }

  const resetForm = () => {
    methods.reset();
    methods.setValue("email", ""); // Reset password field
    methods.setValue("first_name", ""); // Reset password field
    methods.setValue("last_name", ""); // Reset password field
    methods.setValue("password", ""); // Reset password field
    methods.setValue("confirm_password", ""); // Reset confirm_password field
  };

  const Register = methods.handleSubmit(async (data) => {
    try {
      toast.loading("Loading...");
      const res = await api.signUp({
        ...data,
        slug: payload.slug,
        referral: payload.referral,
      });
      toast.remove();
      sessionStorage.setItem("userToken", res.data.token);
      sessionStorage.setItem("user", JSON.stringify(res?.data));

      ReactGA.event({
        category: "User",
        action: "Registration",
        label: "User Registered",
      });
      /*if (
        res.data.status === "in progress" ||
        res.data.status === "pending application"
      ) {
        customToast.success(res.message);
        navigate("/email-verification")
        // setOpenSuccessfulModal(true);
          resetForm()
      } else {
        customToast.success(res.message);
        navigate("/email-verification")
          resetForm()
      }*/
      navigate("/email-verification");
      //console.log(res.message,'mmmm');
      customToast.success(res.message);
      sessionStorage.setItem("user-email", data?.email);
      // setOpenSuccessfulModal(true);
      resetForm();
    } catch (error) {
      toast.remove();
      customToast.error(error?.response?.data?.error?.join(","));
    }
  });

  const handleNavigateToLogin = () => {
    navigate("/login");
  };

  return (
    <div className=" bg-white p-4 lg:p-6">
      <div className="w-full pb-3 h-26 bg-inherit sticky top-0 cursor-pointer">
        <div className="h-full">
          <img
            src={logo}
            alt="Test Assessify"
            className="w-56 h-full object-contain"
          />
        </div>
      </div>

      <div className="flex justify-center items-center mt-0  2xl:mt-0  2xl:h-screen">
        {/* {openSuccessfulModal && <SuccessfulReg />} */}

        <div className="my-2 shadow-2xl rounded-[8px] bg-white w-full lg:w-1/2  2xl:mt-20 p-4 2xl:p-6">
          <div className="flex flex-col w-full">
            <div className="flex flex-col justify-center px-4 pt-8 my-auto md:justify-start md:pt-0  lg:px-4">
              <div className="2xl:mb-10">
                <p className="text-2xl text-darkBlue pt-4 mb-6 font-semibold text-center">
                  Welcome to Test Assesify
                </p>
                <p className="text-lg font-medium text-darkBlue mb-2">
                  How To Apply For Jobs On Test Assessify
                </p>
                <div>
                  <p className="mb-2 text-sm">
                    {" "}
                    <span className="font-medium text-darkBlue text-sm">
                      Step 1 :
                    </span>{" "}
                    Create a profile or log in to your existing account
                  </p>
                  <p className="mb-2 text-sm">
                    <span className="font-medium text-darkBlue text-sm">
                      Step 2 :
                    </span>{" "}
                    Apply for a role and submit your application.
                  </p>
                  <p className="mb-2 text-sm">
                    <span className="font-medium text-darkBlue text-sm">
                      Step 3 :
                    </span>{" "}
                    Get notified and track your progress.
                  </p>
                </div>
                <p className="text-xl font-medium text-darkBlue mt-2">
                  Create Account
                </p>
              </div>
              <FormProvider {...methods}>
                <form className="" onSubmit={(e) => e.preventDefault}>
                  <div className="">
                    <div className="flex relative py-2 2xl:mb-5 group">
                      <Input
                        name="email"
                        // value={values.email}
                        // onChange={handleInputChange}
                        isIcon
                        placeholder="Enter your email"
                        rules={{
                          required: "Email is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        }}
                        className="custom-input text-sm"
                        type="email"
                        ref={(ref) => methods.register(ref)}
                      />
                    </div>
                  </div>
                  <div className="mb-1 2xl:mb-10">
                    <Input
                      // id="first_name"
                      name="first_name"
                      placeholder="Enter your first name"
                      rules={{ required: "First Name is required" }}
                      type="text"
                      ref={(ref) => methods.register(ref)}
                    />
                  </div>
                  <div className="mb-1 2xl:mb-10">
                    <Input
                      // id="last_name"
                      name="last_name"
                      // value={values.last_name || ""}
                      placeholder="Enter your last name"
                      // onChange={handleInputChange}
                      rules={{ required: "Last Name is required" }}
                      type="text"
                      ref={(ref) => methods.register(ref)}
                    />
                  </div>
                  <div className="mb-1 2xl:mb-10">
                    <Input
                      name="password"
                      placeholder="Enter  password"
                      rules={{
                        required: "Password is required",
                        pattern: {
                          value:
                            /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
                          message:
                            "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                        },
                      }}
                      type="password"
                      ref={(ref) => methods.register(ref)}
                    />
                  </div>
                  <ul className="my-3 bg-white p-2 w-[90%] rounded-lg">
                    <li
                      className={`${
                        `${watch("password")}`.length < 8
                          ? "text-red-500"
                          : "text-green-500"
                      } bi bi-check-circle text-sm py-1 pl-2`}
                    >
                      &nbsp; Password must be at least 8 characters long.
                    </li>
                    <li
                      className={`${
                        !/[A-Z]/.test(watch("password"))
                          ? "text-red-500"
                          : "text-green-500"
                      } bi bi-check-circle text-sm py-1 pl-2`}
                    >
                      &nbsp; Password must contain at least one uppercase
                      letter.
                    </li>
                    <li
                      className={`${
                        !/[a-z]/.test(watch("password"))
                          ? "text-red-500"
                          : "text-green-500"
                      } bi bi-check-circle text-sm py-1 pl-2`}
                    >
                      &nbsp; Password must contain at least one lowercase
                      letter.
                    </li>
                    <li
                      className={`${
                        !/\d/.test(watch("password"))
                          ? "text-red-500"
                          : "text-green-500"
                      } bi bi-check-circle text-sm py-1 pl-2`}
                    >
                      &nbsp; Password must contain at least one number.
                    </li>
                    <li
                      className={`${
                        !/[!@#$%^&*()_+}{"':;?/>.<,]/.test(watch("password"))
                          ? "text-red-500"
                          : "text-green-500"
                      } bi bi-check-circle text-sm py-1 pl-2`}
                    >
                      &nbsp; Password must contain at least one special
                      character.
                    </li>
                  </ul>
                  <div className="mb-1 2xl:mb-10">
                    <Input
                      name="password_confirmation"
                      placeholder="Confirm password"
                      rules={{
                        required: "Password is required",
                        validate: (value) =>
                          value === watch("password") ||
                          "Passwords do not match",
                      }}
                      type="password"
                      ref={(ref) => methods.register(ref)}
                    />
                  </div>
                  <div className="sm:flex mt-3 justify-between items-center">
                    <button
                      type="submit"
                      onClick={Register}
                      className="w-full sm:w-fit text-center px-12 py-2  rounded-lg text-base font-semibold  text-white transition duration-200 ease-in bg-[#88a848] shadow-md active:border-none active:outline-none focus:border-none focus:outline-none "
                    >
                      <span className="text-sm 2xl:text-md">Sign Up</span>
                    </button>
                    <div className="py-2 text-center">
                      <p className="text-md">
                        Already have an account? &nbsp;
                        <button
                          type="button"
                          onClick={handleNavigateToLogin}
                          className="font-semibold underline text-primary"
                        >
                          Login
                        </button>
                      </p>
                    </div>
                  </div>
                </form>
              </FormProvider>

              {/* <div className="mt-4 ">
                  {" "}
                  <button class="w-full flex justify-center px-4 py-2 border  gap-2 border-slate-200 rounded-lg text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150">
                    <img
                      class="w-6 h-6"
                      src="https://www.svgrepo.com/show/475656/google-color.svg"
                      loading="lazy"
                      alt="google logo"
                    />
                    <span>Sign up with Google</span>
                  </button>
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
