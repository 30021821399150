import React, { useState, useRef } from "react";
import { api } from "../../../api";
import { useForm, useWatch } from "react-hook-form";
import { useMutation } from "react-query";
import { faEye, faEyeSlash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast from "react-hot-toast";
import { useSearchParams, useParams, useNavigate, useLocation } from "react-router-dom";
import { useCustomToast } from "../../../components/customtoast/CustomToastProvider";

const ForgotPassword = ({ handleModal, setScreenId }) => {
  const [otpStatus, setOtpStatus] = useState(false);
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const campaign_id = searchParams.get('campaign-id');
  const affiliate_id = searchParams.get('affiliate-id');
  const {id}  = useParams()
  const location = useLocation()
  const customToast = useCustomToast()
  const { mutateAsync: GetOtp } = useMutation(api.forgotPassword);
  const { mutateAsync: ResetPassword } = useMutation(api.resetPassword);

  const backdropRef = useRef(null);

  const submitAffiliateTagMutation = useMutation(api.trackUpdate,{
    onSuccess: data => {
       console.log(data,'success');
    }
  });

  const { register, handleSubmit } = useForm();

  const handleBackdropClick = (event) => {
    // Check if the backdrop element was clicked (not its children)
    if (event.target === backdropRef.current) {
      handleModal();
    }
  };

  const handleOTP = async (values) => {
    const { email } = values;
    toast.loading("Loading...");
    try {
      const data = await GetOtp({ 
         email,
         base_url: window.location.origin,
         campaign_id, 
         affiliate_slug: affiliate_id,
         campaign_slug: id,
        });
      toast.remove();
      //toast.success(data?.message, { duration: 50000 });
      //setOtpStatus(true);
      //customToast.success('A reset password link was sent to your mail box.')
      sessionStorage.setItem('forgot-password-redirect-url', location.pathname + location.search)
      navigate(`/reset-password?email=${sessionStorage.getItem('forgot_password_email')}`)
      document.querySelector('#close-forgot-password-modal').click()
     // navigate('/reset-password')
     //if(affiliate_id){
      submitAffiliateTagMutation.mutate({
        email: sessionStorage.getItem('forgot_password_email'),
        latest_page: 'forgot-password',
        tag: sessionStorage.getItem('visitor-fingerprint'),
      })
    //}
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
    }
    // console.log(email);
  };

  const handleForgotPassword = async (values) => {
    toast.loading("Loading...");
    try {
      const data = await ResetPassword(values);
      toast.remove();
      toast.success(data?.message);
      setTimeout(() => {
        setScreenId(0);
      }, 500);
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleShowConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  };

    return (
      <div
        className="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center"
        ref={backdropRef}
        onClick={handleBackdropClick}
      >
        <div
          className="rounded-lg w-full mx-auto p-7 bg-white"
          style={{ maxWidth: "500px" }}
        >
          <div className="w-full flex items-center justify-end">
            <button onClick={handleModal} id="close-forgot-password-modal">
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
          <form className="w-full" onSubmit={handleSubmit(handleOTP)}>
            <p>Enter your Email</p>
            <input
              className="block text-baseFont w-full border border-slate-300 p-2 rounded-md mb-4 outline-none focus:ring-0 focus:border-armyGreen"
              type="email"
              {...register("email", {
                required: true,
              })}
              placeholder="email..."
              onBlur={ e => sessionStorage.setItem('forgot_password_email',e.target.value) }
            />
            <input
              id="submit"
              type="submit"
              value="Reset Password"
              className="hover:text-400 text-baseFont w-full mt-4 mx-auto rounded-md cursor-pointer bg-armyGreen text-white py-2"
            />
          </form>
        </div>
      </div>
    );
};

export default ForgotPassword;
