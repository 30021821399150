import React, { useState } from "react";
import toast from "react-hot-toast";
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
  Link,
} from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
//import logo from "";
import { api } from "../../api";
import UpdatedNavbar from "../../layout/UpdateNavbar";
import { hashSync } from "bcryptjs";
import ReactGA from "react-ga";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const email_ = searchParams.get("email");
  const [email, setEmail] = useState(email_ !== "null" && email_ ? email_ : "");
  const [accountType, setAccounType] = React.useState("candidate");
  const navigate = useNavigate();
  const hash = searchParams.get("h");
  const campaign_id = searchParams.get("campaign_id");
  const campaign_slug = searchParams.get("campaign_slug");
  const affiliate_slug = searchParams.get("affiliate_slug");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  //const navigate = useNavigate();
  const params = useParams();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    let payload = {
      password: newPassword,
      hash: "547hwgwgw737484748",
      email: email,
      password_confirmation: confirmPassword,
    };
    if (newPassword != confirmPassword) {
      toast.error("Password not matched");
      return;
    } else if (!newPassword || !confirmPassword) {
      toast.error("All fields are required");
      return;
    }
    try {
      toast.loading("Loading...");
      const res = await api.resetPassword(payload);
      toast.remove();
      toast.success(res.message);
      //toast.success("Logged in successfully...");
      ReactGA.event({
        category: "User",
        action: "Password Reset",
        label: "User Reset Password",
      });
      sessionStorage.setItem("userToken", res.data.token);
      sessionStorage.setItem("user", JSON.stringify(res?.data));
      sessionStorage.setItem("account-type", hashSync(accountType, 10));
      sessionStorage.setItem("is-authenticated", "1");
      /* navigate(`/job-listings/apply/${campaign_slug}?campaign-id=${campaign_id}&auth=true${
        affiliate_slug ? `&affiliate-id=${affiliate_slug}` : ''
      }`)*/
      navigate(sessionStorage.getItem("forgot-password-redirect-url"));
    } catch (error) {
      toast.remove();
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="bg-white">
      <UpdatedNavbar />
      <div className="container relative grid place-items-center mt-5 2xl:mt-0 2xl:fixed 2xl:h-screen ">
        <div className="bg-cover bg-no-repeat  bg-hero-img w-full  md:w-[600px] lg:w-[900px] rounded-xl p-10  shadow-2xl">
          <div className="my-2 shadow-2xl rounded-[8px] bg-white lg:w-1/2 p-2 py-10">
            <div className="flex flex-col w-full">
              <div className="flex flex-col justify-center px-4 pt-3 my-auto md:justify-start md:pt-0  lg:px-4">
                <p className="text-lg text-darkBlue pt-0 mb-2 font-semibold">
                  Reset Password
                </p>
                <p className="text-base font-normal text-slate-5">
                  Please create a new password
                </p>
                <form className="pt-3 md:pt-4" onSubmit={handleResetPassword}>
                  <div className="flex flex-col pt-4 mb-0">
                    <div className="flex relative ">
                      <span className=" inline-flex rounded-l-lg items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-xs">
                        <svg
                          width="15"
                          height="15"
                          fill="currentColor"
                          viewBox="0 0 1792 1792"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z"></path>
                        </svg>
                      </span>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="flex-1 appearance-none rounded-r-lg border border-gray-300 w-full py-3  px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-[#88a848] focus:border-transparent"
                        placeholder="Enter your email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col pt-0 mb-4">
                    <div className="flex relative ">
                      {/* <span className=" inline-flex rounded-l-lg items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-xs">
                        <svg
                          width="15"
                          height="15"
                          fill="currentColor"
                          viewBox="0 0 1792 1792"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z"></path>
                        </svg>
                      </span>  
                      <input
                        type="otp"
                        id="otp"
                        name="otp"
                        className="flex-1 appearance-none rounded-lg border border-gray-300 w-full py-3  px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-[#88a848] focus:border-transparent"
                        placeholder="Enter your otp"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      /> */}
                    </div>
                  </div>
                  <div className="flex flex-col pt-4 mb-4">
                    <div className="flex relative ">
                      {/* <span className=" inline-flex rounded-l-lg items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-xs">
                        <svg
                          width="15"
                          height="15"
                          fill="currentColor"
                          viewBox="0 0 1792 1792"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z"></path>
                        </svg>
                      </span> */}
                      <input
                        type={showNewPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        className="flex-1 appearance-none rounded-lg border border-gray-300 w-full py-3  px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-[#88a848] focus:border-transparent"
                        placeholder="Create new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      <button
                        type="button"
                        onClick={handleToggleNewPasswordVisibility}
                        className="absolute right-4 top-1/2 transform -translate-y-1/2"
                      >
                        {showNewPassword ? (
                          <AiOutlineEye className="w-6 h-6" />
                        ) : (
                          <AiOutlineEyeInvisible className="w-6 h-6" />
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col pt-4 mb-4">
                    <div className="flex relative">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        id="confirm_password"
                        name="confirm_password"
                        className="w-full rounded-lg border border-gray-300 py-3 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-[#88a848] focus:border-transparent"
                        placeholder="Confirm your password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />

                      <button
                        type="button"
                        onClick={handleToggleConfirmPasswordVisibility}
                        className="absolute right-4 top-1/2 transform -translate-y-1/2"
                      >
                        {showConfirmPassword ? (
                          <AiOutlineEye className="w-6 h-6" />
                        ) : (
                          <AiOutlineEyeInvisible className="w-6 h-6" />
                        )}
                      </button>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="w-full px-4 py-3 rounded-lg text-base font-semibold text-center text-white transition duration-200 ease-in bg-[#88a848] shadow-md  focus:outline-none focus:ring-2"
                  >
                    <span className="w-full text-text-sm">Reset Password</span>
                  </button>
                </form>
                <div className="py-3  text-center">
                  {/*<p className="text-text-sm">
                    Click here to proceed to &nbsp;
                    <Link to="/" className="font-semibold underline">
                      Login
                    </Link>
                        </p>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
