import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { api } from "../../../api";
import toast from "react-hot-toast";
import { useCustomToast } from "../../../components/customtoast/CustomToastProvider";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function AddNysc({ closeModal, refetchNysc, refetchStatus }) {
  const customToast = useCustomToast();
  const [validationErrors, setValidationErrors] = useState({
    call_up_number: "",
    pop_date: "",
  });

  const [values, setValues] = useState({
    pop_date: "",
    call_up_number: "",
  });

  const [call_up_number, set_call_up_number] = useState("");
  const [isValid, setIsValid] = useState(false);

  const [nyscStatus, setNyscStatus] = useState("ongoing");

  const handleNyscStatus = (e) => {
    setNyscStatus(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });

    // Clear the validation error for the field when the user starts typing again
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleNumber = (e) => {
    set_call_up_number(e.target.value);
  };

  const CreateNyscData = async (e) => {
    e.preventDefault();
    let payload = {
      pop_date: values.pop_date,
      call_up_number: nyscStatus === "completed" ? null : call_up_number,
      certificate_number: nyscStatus === "completed" ? call_up_number : null,
      status: nyscStatus === "completed" ? "completed" : "in progress",
    };

    const newValidationErrors = {};

    if (!isValid) {
      return toast.error(
        `Invalid ${
          nyscStatus === "completed"
            ? "nysc certificate number"
            : "call-up number"
        } `
      );
    }

    if (!values.pop_date) {
      newValidationErrors.pop_date = "This field is required.";
      return;
    }

    if (Object.keys(newValidationErrors).length > 0) {
      setValidationErrors(newValidationErrors);
      return;
    }

    try {
      toast.loading("loading...");
      const res = await api.AddNyscData(payload);

      toast.remove();
      customToast.success(res.message);
      sessionStorage.setItem("progress", "nysc");
      refetchNysc();
      refetchStatus();
      closeModal(false);
    } catch ({ response }) {
      toast.remove();
      customToast.error(response?.error?.message);
    }
  };

  const ValidationText = ({ valid, text }) => {
    return (
      <p
        style={{
          color: valid ? "#8EAF55" : "#e1803b",
          fontSize: "12px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "3px",
        }}
      >
        <div
          style={{
            height: "7px",
            width: "7px",
            backgroundColor: valid ? "#8EAF55" : "#e1803b",
            borderRadius: "50%",
          }}
        ></div>
        {text}
      </p>
    );
  };

  const DischargeCertificateNumber = () => {
    const isValid = /^A00\d{7}$/i.test(call_up_number);

    useEffect(() => {
      if (isValid) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }, [isValid]);

    return (
      <React.Fragment>
        {call_up_number && (
          <ValidationText
            valid={isValid}
            text={
              "Number must start with A00 followed by certificate number..."
            }
          />
        )}
      </React.Fragment>
    );
  };

  const CallUpNumber = () => {
    const split = call_up_number.split("/");

    // console.log(split);

    const isDigitsValid = /^\w{2}$/.test(split[0]);
    const isUniversityValid = /^[A-Z]{3}$/i.test(split[1]);
    const isYearValid = /^\d{4}$/.test(split[2]);
    const isUniqueNumberValid = /^\d{6}$/.test(split[3]);

    // console.log(
    //   isDigitsValid,
    //   isUniqueNumberValid,
    //   isUniversityValid,
    //   isYearValid
    // );

    useEffect(() => {
      if (
        isDigitsValid &&
        isUniversityValid &&
        isYearValid &&
        isUniqueNumberValid
      ) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }, [isDigitsValid, isUniqueNumberValid, isUniversityValid, isYearValid]);

    // console.log(isValid, "ln148");

    return (
      <React.Fragment>
        {call_up_number && (
          <div className="flex w-full flex-col gap-1 p-2">
            <ValidationText
              valid={isDigitsValid}
              text={"Two letters of state served."}
            />
            <ValidationText
              valid={isUniversityValid}
              text={"Three letters of school attended."}
            />
            <ValidationText
              valid={isYearValid}
              text={"Four digits of year served."}
            />
            <ValidationText
              valid={isUniqueNumberValid}
              text={"Six digits representing the unique number."}
            />
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="fixed z-[100000] top-0 left-0 w-[100vw] h-[100vh] bg-black bg-opacity-50">
      <div className="flex items-center justify-center h-full w-full">
        <div className="w-[96%] overflow-y-scroll z-[100000] h-[400px] md:h-[500px] md:w-[600px] bg-white rounded-[15px] p-8 md:p-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
            Add Nysc Detail
          </h3>
          <select
            className={`rounded-lg flex-1 border border-slate-300 py-4 px-4 bg-customGrey text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent`}
            onChange={handleNyscStatus}
          >
            <option value={"ongoing"}>On-going</option>
            <option value={"completed"}>Completed</option>
          </select>
          <div className="flex justify-between mb-4"></div>
          <form onSubmit={CreateNyscData}>
            <div className="mb-4">
              <div className=" flex flex-col">
                <label
                  htmlFor="call_up_number"
                  className="mb-4 text-lightGrey text-xs"
                >
                  {nyscStatus === "completed"
                    ? "Nysc Certificate Number"
                    : "Call-Up Number"}
                </label>
                <input
                  value={call_up_number}
                  onChange={handleNumber}
                  type="text"
                  className={`rounded-lg flex-1 border border-lightGrey
               py-4 px-4 bg-customGrey text-gray-700 placeholder-gray-400 focus:border-armyGreen shadow-sm text-base outline-none focus:ring-0`}
                  placeholder={
                    nyscStatus === "completed"
                      ? "A001232321"
                      : "EK/DSU/2014/125333"
                  }
                />
                {nyscStatus === "completed" ? (
                  <DischargeCertificateNumber />
                ) : (
                  <CallUpNumber />
                )}
              </div>
            </div>

            <div className="flex flex-col md:flex-row items-center gap-x-2 mb-4">
              <div className="w-full mb-6 md:mb-0">
                <div className=" flex flex-col">
                  <label
                    htmlFor="pop_date"
                    className="mb-2 text-lightGrey text-xs"
                  >
                    Passing out date
                  </label>
                  {/* <ReactDatePicker
                    value={values.pop_date}
                    
                    onChange={handleInputChange}
                    className={`rounded-lg  flex-1 appearance-none border ${
                      validationErrors.pop_date
                        ? "border-red-500"
                        : "border-lightGrey"
                    } py-4 px-4 bg-customGrey text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent`}
                    name="pop_date"
                  /> */}
                  <input
                    type="date"
                    id="pop_date"
                    value={values.pop_date}
                    onChange={handleInputChange}
                    className={`rounded-lg  flex-1 appearance-none border ${
                      validationErrors.pop_date
                        ? "border-red-500"
                        : "border-lightGrey"
                    } py-4 px-4 bg-customGrey text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent`}
                    name="pop_date"
                    placeholder="dd/mm/yyyy"
                  />
                  {validationErrors.pop_date && (
                    <p className="text-red-500 text-xs mt-1">
                      {validationErrors.pop_date}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="flex gap-x-10 justify-center">
              <button
                type="button"
                className="inline-flex justify-center rounded-md border border-armyGreen  shadow-sm px-8 py-2 md:px-16 md:py-4 bg-white text-sm md:text-lg font-medium text-darkBlue  sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
                onClick={() => closeModal(false)}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="inline-flex justify-center rounded-md border border-none  shadow-sm px-8 py-2 md:px-16 md:py-4 bg-armyGreen text-sm md:text-lg font-medium text-white sm:ml-3 sm:w-auto sm:text-sm mb-4 sm:mb-0"
              >
                Apply
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddNysc;
