import React , {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import { useForm, FormProvider, useController } from "react-hook-form";
import { useLocation, useNavigate, Link, useSearchParams, useParams } from "react-router-dom";
import { api } from "../../../api";
import Input from "../../../components/Global/Input";
import Logo from "../../../components/Logo";
import { useCustomToast } from "../../../components/customtoast/CustomToastProvider";
import { useMutation } from 'react-query';
import { hashSync } from "bcryptjs";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css"; 

function PhoneInputField({ name, control, rules }) {
  const { field } = useController({ name, control, rules });
  return (
    <PhoneInput
      {...field}
      className={`rounded-lg flex-1 appearance-none border ${
        field.value ? "border-lightGrey" : "border-red-500"
      } w-full py-4 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent`}
      international={true}
      defaultCountry="NG"
      placeholder="Your phone number"
    />
  );
}

const Register = ({ setScreenId, handleModal }) => {

  const methods = useForm();
  const { watch, control, handleSubmit, formState: { errors } } = methods;
  const location = useLocation();

  // Extract the slug and referral from url
  const slug = location.pathname.split("/")[2];
  const referral = new URLSearchParams(location.search).get("referral");
  const navigate = useNavigate()
  const [accountChoosen, setAccountChoosen] = useState(false)
  const [searchParams] = useSearchParams();
  const campaign_id = searchParams.get('campaign-id');
  const [accountType, setAccounType] = React.useState("candidate");
  const affiliate_id = searchParams.get('affiliate-id');
  const {id}  = useParams()
  const customtoast = useCustomToast()

  sessionStorage.setItem("slug", slug || null);
  sessionStorage.setItem("referral", referral || null);

  let payload = {
    slug: location.pathname.split("/")[2],
    referral: new URLSearchParams(location.search).get("referral"),
  };

  const resetForm = () => {
    methods.reset();
    methods.setValue("email", ""); // Reset password field
    methods.setValue("first_name", ""); // Reset password field
    methods.setValue("last_name", ""); // Reset password field
    methods.setValue("password", ""); // Reset password field
    methods.setValue("confirm_password", ""); // Reset confirm_password field
  };

  const submitAffiliateTagMutation = useMutation(api.trackUpdate,{
    onSuccess: data => {
    }
  });

  const Register = methods.handleSubmit(async (data) => {
    try {
      toast.loading("Loading...");
      setAccountChoosen(false)
      const res = await api.signUp({
        ...data,
        slug: null,
        referral: payload.referral,
        base_url: window.location.origin,
        campaign_id, 
        affiliate_slug: affiliate_id,
        campaign_slug: id,
      });
      toast.remove();
      //if(affiliate_id){
        submitAffiliateTagMutation.mutate({
          email: data?.email,
          latest_page: 'registration',
          tag: sessionStorage.getItem('visitor-fingerprint')
        })
      //}
      sessionStorage.setItem("userToken", res.data.token);
      sessionStorage.setItem("user", JSON.stringify(res?.data));
      //setScreenId(2);
      /*customtoast.success(`Registration successful. Verification link was sent to 
      "${data?.email}".
      \n Kindly check your mail box or check spam if you did not recieve the mail directly to your inbox.
    `);*/
      customtoast.success(`Registration successful.`)
      sessionStorage.setItem("user-email", data?.email);
      sessionStorage.setItem("user-password", data?.password);
      localStorage.setItem('redirect-url', window.location.pathname)
      resetForm();
      document.querySelector('#registration-modal-close-btn').click()
      //log them in to be removed.
      /****************************************************** */
      sessionStorage.setItem("account-type", hashSync(accountType, 10));
      sessionStorage.setItem("is-authenticated", "1"); 
      console.log(res.data);
      /****************************************************** */
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
      if( response?.data?.message[0] === 'The email has already been taken.'||
          response?.data?.message === 'The email has already been taken.' ||
          response?.data?.error === 'The email has already been taken.'
       ){
        setAccountChoosen(true)
      }
    }
  });

  const validatePhoneNumber = (value) => {
    const phoneNumberLength = value.replace(/\D/g, '').length; // Remove non-digit characters and get length
    const countryCode = value.startsWith("+234") ? "NG" : "Other"; // Check if the number is Nigerian

    if (countryCode === "NG" && phoneNumberLength < 11) {
      return "Nigerian phone number must be at least 11 digits long";
    } else if (countryCode !== "NG" && phoneNumberLength < 6) {
      return "Phone number must be at least 5 digits long";
    }
    return true;
  };

  return (
    <div
      className="rounded-lg w-full mx-auto p-0 md:p-4 h-[95vh] overflow-y-scroll bg-white max-w-[96%] md:max-w-[600px]"      
    >  
      <div className="w-full flex items-center justify-end pr-3">
        <button onClick={handleModal} className="sticky" id='registration-modal-close-btn'>
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>
      <div className="rounded-[8px] bg-white w-full px-4">
        <div className="flex flex-col w-full">
          <div className="flex flex-col justify-center px-4 pt-1 my-auto md:justify-start md:pt-0 lg:px-4">
            <div className="w-full flex items-center justify-between">
              {" "}
              <h1 className="text-lg ">Register Account</h1>
              <Logo height="38" />
            </div>
            <FormProvider {...methods}>
              <form className="" onSubmit={(e) => e.preventDefault}>
                { accountChoosen &&
                  <p className="py-3 text-center text-xs px-2 bg-yellow-100">
                   We found an account for the email! or  to get signed in with ease.
                    <button
                       className="text-md underline text-blue-500 pl-1"
                       type="button"
                       onClick={ () => setScreenId(0) }
                      >
                      Login now
                    </button> or click  
                   <button 
                      className="text-md underline text-blue-500 px-1"
                      //to={`/email-verification?email=${watch('email')}`}
                      onClick={ () => setScreenId(4) }
                      >
                      Send Login Link
                    </button>
                    .
                </p>}
                <div className="">
                  <div className="flex relative pb-2 group mb-1">
                    <Input
                      name="email"
                      // value={values.email}
                      // onChange={handleInputChange}
                      isIcon
                      placeholder="Enter your email"
                      rules={{
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      }}
                      className="custom-input text-sm"
                      type="email"
                      ref={(ref) => methods.register(ref)}
                    />
                  </div>
                </div>
                <div className="mb-1">
                  <Input
                    // id="first_name"
                    name="first_name"
                    placeholder="Enter your first name"
                    rules={{ required: "First Name is required" }}
                    type="text"
                    ref={(ref) => methods.register(ref)}
                  />
                </div>
                <div className="mb-1">
                  <Input
                    // id="last_name"
                    name="last_name"
                    // value={values.last_name || ""}
                    placeholder="Enter your last name"
                    // onChange={handleInputChange}
                    rules={{ required: "Last Name is required" }}
                    type="text"
                    ref={(ref) => methods.register(ref)}
                  />
                </div>
                <div className="mb-1 mt-5">
                    <PhoneInputField
                        name="phone_number"
                        control={control}
                        rules={{ required: "Phone number is required", validate: validatePhoneNumber }}
                      />
                      {errors.phone_number && (
                        <p className="text-red-500 mt-1">{errors.phone_number.message}</p>
                  )}
                </div>
                <div className="mb-1 password-wrapper">
                  <Input
                    name="password"
                    className='text-xs'
                    placeholder="Enter password"
                    type="password"
                    ref={(ref) => methods.register(ref)}
                    rules={{
                      required: "Password is required",
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters long",
                      },
                      pattern: {
                        value: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?])[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]{8,}$/,
                        message: "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                    },
                    }}
                  />
                </div>
                <ul className='my-3 bg-white p-2 w-[90%] rounded-lg'>
                      <li className={`${ 
                                         `${watch('password')}`.length < 8 ? 'text-red-500' : 'text-green-500'
                                    } bi bi-check-circle text-sm py-1 pl-2`}>
                          &nbsp; Password must be at least 8 characters long.
                      </li>
                      <li className={`${ 
                                        !/[A-Z]/.test(watch('password')) ? 'text-red-500' : 'text-green-500'
                                    } bi bi-check-circle text-sm py-1 pl-2`}>
                          &nbsp; Password must contain at least one uppercase letter.
                      </li>
                      <li className={`${ 
                                        !/[a-z]/.test(watch('password')) ? 'text-red-500' : 'text-green-500'
                                    } bi bi-check-circle text-sm py-1 pl-2`}>
                          &nbsp;  Password must contain at least one lowercase letter.
                      </li>
                      <li className={`${ 
                                      !/\d/.test(watch('password')) ? 'text-red-500' : 'text-green-500'
                                    } bi bi-check-circle text-sm py-1 pl-2`}>
                          &nbsp;  Password must contain at least one number.
                      </li>
                      <li className={`${ 
                                        !/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]/.test(watch('password')) ? 'text-red-500' : 'text-green-500'
                                    } bi bi-check-circle text-sm py-1 pl-2`}>
                          &nbsp; Password must contain at least one special character.
                      </li>
                    </ul>
                <div className="mb-1 password-wrapper">
                  <Input
                    name="password_confirmation"
                    placeholder="Confirm password"
                    rules={{
                      required: "Password confirmation is required",
                      validate: (value) =>
                        value === watch("password") || "Passwords do not match",
                    }}
                    type="password"
                    ref={(ref) => methods.register(ref)}
                  />
                </div>
                {/* {errors.confirm_password && (
          <p className="text-red-500 mt-1">{errors.confirm_password.message}</p>
        )} */}
                <div className="sm:flex mt-5 justify-center items-center">
                  <button
                    type="submit"
                    onClick={Register}
                    className="w-full sm:w-fit text-center px-16 py-2 mb-1 rounded-lg text-base font-semibold  text-white transition duration-200 ease-in bg-[#88a848] shadow-md active:border-none active:outline-none focus:border-none focus:outline-none "
                  >
                    <span className="text-sm  2xl:text-md">Sign Up</span>
                  </button>
                  {/* <div className="py-2 text-center">
                    <p className="text-md">
                      Already have an account? &nbsp;
                      <button
                        type="button"
                        onClick={() => setScreenId(0)}
                        className="font-semibold underline text-primary"
                      >
                        Login
                      </button>
                    </p>
                  </div> */}
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
