import React from 'react'
import Swal from 'sweetalert2';
import { useMutation } from "react-query";
import { api } from "./../../../api/index";

const NewsLetterSubscribe = () => {

  const [email, setEmail] = React.useState('')

  const sentMailMutation = useMutation(api.newsLetterSubscribe, {
    onSuccess: data => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'You had successfully subscribed to our newsletters.',
      })
    },
    onError: (error) => {       
       Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response.data.error,
      })
    },
  })

  const handlechange = e => {
     setEmail(e.target.value)
  }

  const handleSubmit = () => {
    if(email === ''){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Your email address is required.',
      })
    }
    else if( email !== '' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Invalid email address.',
      })
    }
    else{
       sentMailMutation.mutate({
         email,
         name: 'Ahmed'
       })
    }
  }

  return (
    <div className='md:flex justify-between items-center mb-3 px-1'>
       <h3 className='text-xl pl-2 mb-3 md:mb-0 font-semibold'>
        Get  news on all the latest updates straight to your mail
       </h3>
       <div class="md:mr-8">
    <div class="relative mb-4 flex w-full  items-stretch">
      <input
        type="text"
        className="relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto rounded-l border border-solid border-neutral-300 bg-white bg-clip-padding px-3 py-[0.6rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-armyGreen focus:text-neutral-700 focus:shadow-0 focus:outline-none "
        placeholder="subscribe"
        aria-label="Search"
        aria-describedby="button-addon1" 
        value={email}
        onChange={handlechange}
        />
  
      <button
        class="relative z-[2] flex items-center rounded-r bg-red-500 px-8 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-armyGreen hover:shadow-lg focus:bg-armyGreen focus:shadow-lg focus:outline-none focus:ring-0 active:bg-armyGreen active:shadow-lg"
        type="button"
        id="button-addon1"
        data-te-ripple-init
        data-te-ripple-color="light"
        onClick={handleSubmit}
        >
          SubsCribe
      </button>
    </div>
  </div>
    </div>
  )
}

export default NewsLetterSubscribe