import React from 'react'
import { useMutation } from "react-query";
import { api } from '../../../api/index';
import ScaleLoader_ from '../../../components/ScaleLoader';
import { Modal, Ripple, initTE, } from "tw-elements";
import { compareSync } from 'bcryptjs';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';

const TopUpConfirmationModal = ({ credits, price, }) => {

 React.useEffect( () => {
    initTE({ Modal, Ripple });
 }, [])
 
 const navigate = useNavigate()
 const accountType = sessionStorage.getItem("account-type") ? sessionStorage.getItem("account-type") : ''

 const  getRoute = () => {
  const hostname = window.location.hostname;
  if (hostname.includes("playpen") || hostname.includes("localhost")) {
    return "http://playpenadvisor.testassessify.com/register";
  } else {
    return "https://controller.testassessify.com/register";
  }
};

 const topUpAccountMutation = useMutation(api.topUpAccount, {
      onSuccess: data => {
      toast.remove()
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Topup successful',
      })
    },
    onError: (error) => {
      toast.remove()
       Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unknown error occured',
      })
    },
    onMutate: () => {
      toast.loading('loading...')
    }
 })

 const topUpAccount = () => {
   if(!compareSync("admin", accountType)) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Please Login as a client admin',
    })
      navigate('/login')
   }
   else topUpAccountMutation.mutate( { credits, amount: price } )
 }

  return (
    <>  { topUpAccountMutation.isLoading && <ScaleLoader_ /> }
       <div
            data-te-modal-init
            className="fixed left-0 top-0 z-[1055]  hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true">
        <div
            data-te-modal-dialog-ref
            style={{ backgroundColor: '#fff'}}
            className="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px]">
            <div
            style={{ backgroundColor: '#fff'}}
            className="min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            <div
                style={{ backgroundColor: '#fff'}}
                className="flex flex-shrink-0 items-center justify-between rounded-t-md shadow-lg p-4 dark:border-opacity-50">               
                <h5
                className="text-xl font-medium leading-normal text-gray-900"
                id="exampleModalLabel">
                    Confirm Account Top Up
                </h5>
        <button
          type="button"
          className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
          data-te-modal-dismiss
          aria-label="Close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-6 w-6">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
       
      <div className="relative flex-auto p-4" data-te-modal-body-ref  style={{ backgroundColor: '#fff'}}>
          <h5 className='mt-4 mb-2 text-center'>
              <span className='bi bi-recycle text-7xl'></span>
          </h5>
          <p className='text-md text-center mt-1'>
             Are you sure you want to top up your account with {credits} &nbsp; 
             assessment credits for ${price} ? 
          </p>
      </div>
      
      <div
         style={{ backgroundColor: '#fff'}}
        className="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">         
        <button
          type="button"
          className="ml-1 mr-5 text-white inline-block rounded bg-red-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-red-500-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-red-500-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-red-500-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
          data-te-ripple-init
          data-te-modal-dismiss
          data-te-ripple-color="light">
           Cancel
        </button>
        <button
          type="button"
          className="inline-block rounded bg-armyGreen px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-green-500 focus:bg-armyGreen focus:outline-none focus:ring-0 active:bg-armyGreen"
          data-te-modal-dismiss
          data-te-ripple-init
          data-te-ripple-color="light"
          onClick={ () => {
              window.open(getRoute(),'_blank')
            } }
          >
           Yes, Continue <span className='bi bi-arrow-right'></span>
        </button>
      </div>
    </div>
  </div>
</div>
    </>
  )
}

export default TopUpConfirmationModal