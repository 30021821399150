import React, {useState, useEffect } from 'react'
import { useQuery, useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { api } from '../../../api';
import { useCustomToast } from "../../../components/customtoast/CustomToastProvider";
import ReactStars from "react-rating-stars-component";
import feedbackImg from '../../../images/assets/feedback-message-4644 (1).png'

const FeedbackModal = ({className, headerText, setOpenModal }) => {

  const [feedback, setFeedback] = useState([]);
  const [errorMessage, setErrorMessage] = useState('')
  const customToast = useCustomToast();
  const [rating, setRating] = useState(0);
  const [text, setText] = useState("");

  const { data: feedbackQuestion, refetch, isLoading } = useQuery(
    "get feedback questions",
    api.getFeedbackQuestions,
    {
      onSuccess: data => {
        const initialFeedback = data?.data?.data?.map(question => ({
          id: question.id,
          response: null,  
          text: question?.text
        }));
        setFeedback(initialFeedback);
      }
    }
  );

 const closeModal = () => setOpenModal(false)

 const handleRadioChange = (questionId, response) => {
  setFeedback(prevFeedback => (
    prevFeedback.map(item => (
      item.id === questionId ? { ...item, response } : item
    ))
  ));
};

const ratingChanged = (newRating) => {
  setRating(newRating);
  
  let label = "";
  if (newRating == 0.5 || newRating == 1) {
    label = "Bad";
  } else if (newRating == 1.5 || newRating == 2) {
    label = "Fair";
  } else if (newRating == 2.5 || newRating == 3) {
    label = "Good";
  } else if (newRating == 3.5 || newRating == 4) {
    label = "Very Good";
  } else {
    label = "Excellent";
  }
};

const ratingLabel = (rating) => {
  if (rating == 0.5 || rating == 1) {
    return "Bad";
  } else if (rating == 1.5 || rating == 2) {
    return "Fair";
  } else if (rating == 2.5 || rating == 3) {
    return "Good";
  } else if (rating == 3.5 || rating == 4) {
    return "Very Good";
  } else {
    return "Excellent";
  }
};

 const handleSubmit = async (e) => {
  e.preventDefault();
  let content = {
    comment: text,
    rating: rating,
  };
  const feedbackSubmitted = feedback.filter( feedback => {
     return feedback?.response !== null
  } )
  if(feedbackSubmitted?.length){          
       setErrorMessage('')
       const feedbackQuestions = feedbackSubmitted.filter( question => question.response !== null )
       try {
        toast.loading("loading...");          
        const res = await api.sendFeedback({ 
              ...content, 
              questions: feedbackQuestions.map( question => {
                return { id: question?.id, response: question?.response }
              })
         });
        toast.remove();
        setOpenModal(false)   
        customToast.success(res.message);            
      } catch (error) {
        setOpenModal(false) 
        customToast.error(error.response.data.error);
        toast.remove()
      }
  }
  else setErrorMessage('No any feedback choosen')     
};

  return (
    <section className='w-[100vw] h-[100vh] top-0 left-0 fixed z-[100000]'>
        <div className='h-full w-full flex items-center justify-center bg-[rgba(0,0,0,0.3)]'>
             <div className={`bg-white rounded-lg ${className}`}>
                <h1 className='flex justify-between px-2 py-4 shadow border-b border-gray-200'>
                     <span className='text-md capitalize'>
                        Kindly provide feedback to help us improve on your experience
                     </span>
                     <i className='bi bi-x-lg hover:cursor-pointer' onClick={closeModal}></i>
                 </h1>               
                <div className='mt-2 py-2 px-4'>
                     <div className='flex items-center justify-center'>
                          <img 
                             src={feedbackImg} alt="feedback img"
                             className='w-[150px] h-[150px]'
                          />
                     </div>
                     <h5 className='text-center text-sm capitalize'>
                       How will you rate our platform based on your experience 
                     </h5>
                     <form onSubmit={handleSubmit}>
            <div className="flex justify-center mb-8">
              <div className="">
                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={44}
                  isHalf={true}
                  emptyIcon={<i className="far fa-star"></i>}
                  halfIcon={<i className="fa fa-star-half-alt"></i>}
                  fullIcon={<i className="fa fa-star"></i>}
                  activeColor="#ffd700"
                />
                <div className="text-center mt-2 text-gray-600 text-sm">
                  {rating > 0 && `${ratingLabel(rating)}`}
                </div>
              </div>
            </div>
            <div className="">
              <h3 className="mb-3 text-sm">
                Tell Us more about Your Experience
              </h3>
              <div className="w-3/4 flex flex-col">
                 {
                    (!isLoading && feedback.length ) ?
                    feedback.map(question => (
                      <div key={question.id}>
                        <p className="text-sm my-3">{question.text}</p>
                         <div className="flex items-center"> 
                          <label className="flex items-center text-sm">
                            <input
                              type="radio"
                              name={`question_${question.id}`}
                              value="true"
                              checked={feedback.find(item => item.id === question.id)?.response === true}
                              onChange={() => handleRadioChange(question.id, true)}
                              className={`relative mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-primary before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]`}
                            />
                            Yes
                          </label>
                          <label className="text-sm flex items-center">
                            <input
                              type="radio"
                              name={`question_${question.id}`}
                              value="false"
                              checked={feedback.find(item => item.id === question.id)?.response === false}
                              onChange={() => handleRadioChange(question.id, false)}
                              className={`ml-8 relative mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-primary before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]`}
                            />
                            No
                          </label>
                         </div>
                      </div>
                    ))
                    :
                    <p className="text-center mt-12 text-sm">Loading...</p>
                 }               
                </div>
                <p className="text-red-500 text-sm">
                    {errorMessage}
                </p>
            </div>
            <div className="">
              <h3 className="text-base font-medium mb-6 mt-3">
                Is there anything you will like us to improve upon?
              </h3>
              <div className="flex gap-x-56 items-end">
                <textarea
                  className="w-full h-32 p-2 bg-white border border-lightGrey rounded-lg focus:outline-none focus:ring-2 focus:ring-lightGrey focus:border-transparent"
                  placeholder="Leave your comment here."
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                ></textarea>
               </div>
              </div>
              <div className="flex flex-wrap md:px-5 md:justify-end  border-gray-200 mt-5 px-3 py-2">                
                <button
                  type="submit"
                  className="bg-armyGreen px-12 py-3 text-sm text-white rounded-lg"
                >
                  Submit Feedback
                </button>
               </div>
            </form>
                </div>
             </div>
        </div>
    </section>
  )
}

export default FeedbackModal