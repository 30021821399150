import React from 'react'
import UpdatedNavbar from './../../layout/UpdateNavbar';
import faqImage from '../../images/assets/faq-header.PNG'
import { Link } from "react-router-dom";
import cylinderImage from '../../images/assets/Group 106.png'
import Footer from '../../layout/Footer'

const FAQs = () => {
  return (
    <div>
        <UpdatedNavbar />
        <div className='bg-[#FEDC8C] mt-0 py-2 mb-5'>
       <div className='md:flex justify-between'>
          <div className='md:w-[60%] flex items-center'>
             <img 
                     src={cylinderImage} alt="ellpise"
                     className='m-hidden ml-5 w-[90px] h-[170px]'
               />
              <div className='ml-[50px] w-[280px] h-[300px] pt-16 '>
                 Quick answers for questions you may have. Can’t find what you are looking for?
                 <Link to='/job-listings' className='button-md mt-4 text-center'>
                    Find jobs
                 </Link>
              </div>
              
          </div>
          <div className='md:w-[40%] px-1 '>
            <img src={faqImage} alt="about us" className='h-[250px] md:h-[300px] object-cover  w-[300px]' />            
          </div>
       </div>
    </div>
    <h5 className='text-center text-xl font-semibold mt-4'>
      Frequently Asked Questions
    </h5>
    <div className='shadow-md mx-2 border-t border-gray-200 mt-[40px] pl-2 pb-2'>
        <h3 className='text-lg font-semibold pt-2'> Can I take the test on my phone?</h3>
        <p className='text-md md:w-[60%] mt-1 '>
               We strongly advise against using your phone for the test. Using your phone can result in your test
             being terminated when calls or SMS come in during the test as this can be considered suspicious
            activities (see the question on suspicious activities for more details).
        </p>
    </div>
    <div className='shadow-md mx-2 border-t border-gray-200 mt-[40px] pl-2 pb-2'>
        <h3 className='text-lg font-semibold pt-2'> Can I take the test more than once? </h3>
        <p className='text-md md:w-[60%] mt-1 '>
        You are only allowed to take the test only once. If you take the test more than once, it is only your
        score from the first attempt that will be recorded.
        </p>
    </div>
    <div className='shadow-md mx-2 border-t border-gray-200 mt-[40px] pl-2 pb-2'>
        <h3 className='text-lg font-semibold pt-2'> Is the test timed? </h3>
        <p className='text-md md:w-[60%] mt-1 '>
           Yes. All tests have a time duration in which they must be completed. Once the duration is
           reached, the test will be terminated, and your score recorded and submitted.
        </p>
    </div>
    <div className='shadow-md mx-2 border-t border-gray-200 mt-[40px] pl-2 pb-2'>
        <h3 className='text-lg font-semibold pt-2'>  What if I mistakenly end the test before I am done </h3>
        <p className='text-md md:w-[60%] mt-1 '>
         Once a test is submitted or closed, it will be assumed that the test has been completed and
submitted. 
        </p>
    </div>
    <div className='shadow-md mx-2 border-t border-gray-200 mt-[40px] pl-2 pb-2'>
        <h3 className='text-lg font-semibold pt-2'> Would I be allowed to retake the test if my test automatically ended because of network or
           internet issues?  </h3>
        <p className='text-md md:w-[60%] mt-1 '>
         No, you would not be allowed to retake the test. You are advised to make sure you have quality
         internet service to avoid this. 
        </p>
    </div>
    <div className='shadow-md mx-2 border-t border-gray-200 mt-[40px] pl-2 pb-2'>
        <h3 className='text-lg font-semibold pt-2'>
        Would I be allowed to use the calculator on my system for the test for mathematical questions?
           </h3>
        <p className='text-md md:w-[60%] mt-1 '>
         Opening another app on your device while taking the test is considered an unusual behaviour so
          we advise you have a physical calculator for the test.   
        </p>
    </div>
    <div className='shadow-md mx-2 border-t border-gray-200 mt-[40px] pl-2 pb-2'>
        <h3 className='text-lg font-semibold pt-2'> My test just ended. I don’t know what happened. What should I do? </h3>
        <p className='text-md md:w-[60%] mt-1 '>
            Your test can be terminated for several reasons – internet connection, suspicious behaviour, or test
            time       elapsed. <br /><br />
            Please ensure you have good and reliable internet before starting the examination to prevent
            termination due to poor internet connection.  <br /><br />
            If you perform five (5) suspicious activities, your test will be terminated. Every time a suspicious
           activity is noticed, you will get a warning. By the fifth (5th) warning, your test will be terminated. 
        </p>
    </div>
    <div className='shadow-md mx-2 border-t border-gray-200 mt-[40px] pl-2 pb-2'>
        <h3 className='text-lg font-semibold pt-2'>  What are suspicious activities?  </h3>
        <p className='text-md md:w-[60%] mt-1'>
            Your test will be terminated if you perform five (5) suspicious activities. The following activities are
            considered suspicious activities /unusual behaviour:
            <ul>
               <li className='mt-2'>
                  1. Minimizing the browser.
               </li>
               <li className='mt-2'>
                  2.  Resizing the browser.
               </li>
               <li className='mt-2'>
                  3.  Opening a new tab.
               </li>
               <li className='mt-2'>
                  4. Opening a new program.
               </li>
               <li className='mt-2'>
                  5.  Pressing Ctrl + C.
               </li>
               <li className='mt-2'>
                  6.  Pressing Ctrl + V
               </li>
               <li className='mt-2'>
                  7.  Pressing Print Screen.
               </li>
               <li className='mt-2'>
                  8.  Pressing F12.
               </li>
            </ul>
        </p>
    </div>
      <div className='mt-5'>
       <Footer />
      </div>
    </div>
  )
}

export default FAQs